import React, { useEffect, useState, useCallback } from 'react';
import { Container, Typography, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, TextField, Link } from '@mui/material';
import axios from 'axios';

const PigGame = () => {
  const [view, setView] = useState('pigGames'); // 'pigGames', 'purchases', 'prizes', or 'top'
  const [data, setData] = useState([]);
  const [totalPurchases, setTotalPurchases] = useState(0);
  const [loading, setLoading] = useState(true);
  const [validatedCount, setValidatedCount] = useState(0);
  const [selectedDate, setSelectedDate] = useState(''); // Date selected for prizes and top views
  const [awardLoading, setAwardLoading] = useState(false);

  // Fetch data based on view and date
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      let endpoint;

      // Set endpoint based on view
      if (view === 'pigGames') {
        endpoint = '/admin/pig-game/unvalidated';
      } else if (view === 'purchases') {
        endpoint = '/admin/pigpurchases';
      } else if ((view === 'prizes' || view === 'top') && selectedDate) {
        endpoint = view === 'prizes' 
          ? `/admin/pig-game/prizes/${selectedDate}` 
          : `/admin/pig-game/top/${selectedDate}`;
      }

      // Only fetch data if we have the correct endpoint (valid for prizes/top only when selectedDate is provided)
      if (endpoint) {
        const response = await axios.get(endpoint, {
          headers: { Authorization: `Bearer ${token}` },
        });

        // Handle response data for different views
        if (view === 'pigGames') {
          setData(response.data.games);
          setValidatedCount(response.data.validatedCount);
        } else if (view === 'purchases') {
          const total = response.data.reduce((sum, purchase) => sum + purchase.price, 0);
          setData(response.data);
          setTotalPurchases(total);
        } else {
          setData(response.data);
        }
        setLoading(false);
      } else {
        setLoading(false);
        alert('Please select a date.');
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }, [view, selectedDate]);

  // Effect to fetch data on initial load or when view changes
  useEffect(() => {
    if ((view === 'prizes' || view === 'top') && !selectedDate) {
      return; // Prevent fetching data if date is not selected for prizes/top views
    }
    fetchData();
  }, [fetchData, view, selectedDate]);

  // Approve game action
  const approveGame = async (id) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`/admin/pig-game/approve/${id}`, {}, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchData(); // Refresh the data after approval
    } catch (err) {
      console.error('Error approving game', err);
    }
  };

  // Decline game action
  const declineGame = async (id) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`/admin/pig-game/decline/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchData(); // Refresh the data after decline
    } catch (err) {
      console.error('Error declining game', err);
    }
  };

  // Handle date change for "prizes" and "top" views
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  // Handle prize awarding for a selected date
  const handleAwardPrizes = async () => {
    if (!selectedDate) {
      alert('Please select a date first');
      return;
    }
    setAwardLoading(true);
    try {
      const token = localStorage.getItem('token');
      await axios.post('/admin/pig-game/calculate-prizes', { date: selectedDate }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert('Prizes awarded successfully');
    } catch (err) {
      console.error('Error awarding prizes:', err);
      alert('Error awarding prizes');
    } finally {
      setAwardLoading(false);
    }
  };

  // Render the correct table based on the view
  const renderTable = () => {
    if (view === 'prizes' || view === 'top') {
      return (
        <>
          <TextField
            label="Select Date"
            type="date"
            value={selectedDate}
            onChange={handleDateChange}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAwardPrizes}
            disabled={awardLoading || !selectedDate}
          >
            {awardLoading ? 'Processing...' : 'Award Prizes for Selected Date'}
          </Button>
          {loading ? (
            <CircularProgress />
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Place</TableCell>
                    <TableCell>User</TableCell>
                    <TableCell>{view === 'prizes' ? 'Prize (FERMA)' : 'Score'}</TableCell>
                    {view === 'prizes' && <TableCell>Score</TableCell>}
                    <TableCell>Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((item) => (
                    <TableRow key={item._id}>
                      <TableCell>{item?.place}</TableCell>
                      <TableCell>{item.userId?.username || `${item.userId?.firstName} ${item.userId?.lastName}`}</TableCell>
                      <TableCell>{view === 'prizes' ? item.prize : item.score}</TableCell>
                      {view === 'prizes' && <TableCell>{item.score}</TableCell>}
                      <TableCell>{new Date(item.createdAt || item.date).toLocaleDateString()}</TableCell>                      
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      );
    }

        if (view === 'pigGames') {
      return (
        <Container>
          <Paper elevation={3} sx={{ padding: 3, margin: 3 }}>
            <Typography variant="h4" gutterBottom>
              Unvalidated Pig Games ({validatedCount} validated this batch)
            </Typography>
            {loading ? (
              <CircularProgress />
            ) : data.length === 0 ? (
              <Typography>No unvalidated pig games available</Typography>
            ) : (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>User</TableCell>
                      <TableCell>Score</TableCell>
                      <TableCell>Max Score</TableCell>
                      <TableCell>Avg Jumps</TableCell>
                      <TableCell>Game Duration</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((game) => (
                      <TableRow key={game._id}>
                        <TableCell>{game.userId?.username || `${game.userId?.firstName} ${game.userId?.lastName}`}</TableCell>
                        <TableCell>{game.score}</TableCell>
                        <TableCell>{game.maxScore}</TableCell>
                        <TableCell>{game.avgJumps?.toFixed(2)}</TableCell>
                        <TableCell>{game.gameDuration}</TableCell>
                        <TableCell>{new Date(game.createdAt).toLocaleString()}</TableCell>
                        <TableCell>
                          <Button onClick={() => approveGame(game._id)} variant="contained" color="primary">
                            Approve
                          </Button>
                          <Button onClick={() => declineGame(game._id)} variant="contained" color="secondary" sx={{ ml: 2 }}>
                            Decline
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
        </Container>
      );
    }

    if (view === 'purchases') {
      return (
        <>
          <Typography variant="h6" gutterBottom>
            Total Purchases: {totalPurchases} FERMA
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>User</TableCell>
                  <TableCell>Games Purchased</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Currency</TableCell>
                  <TableCell>Purchase Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((purchase) => (
                  <TableRow key={purchase._id}>
                    <TableCell>
                      <Link to={`/user/${purchase.userId._id}`}>
                        {purchase.userId.username || `${purchase.userId.firstName} ${purchase.userId.lastName}`}
                      </Link>
                    </TableCell>
                    <TableCell>{purchase.gamesPurchased}</TableCell>
                    <TableCell>{purchase.price}</TableCell>
                    <TableCell>{purchase.currency}</TableCell>
                    <TableCell>{new Date(purchase.createdAt).toLocaleString()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      );
    }
  };

  return (
    <Container>
      <Paper elevation={3} sx={{ padding: 3, margin: 3 }}>
        <Typography variant="h4" gutterBottom>
          Pig Game
        </Typography>

        {/* View selection buttons */}
        <div style={{ marginBottom: '20px' }}>
          <Button variant={view === 'pigGames' ? 'contained' : 'outlined'} onClick={() => setView('pigGames')}>
            Pig Games
          </Button>
          <Button variant={view === 'purchases' ? 'contained' : 'outlined'} onClick={() => setView('purchases')} sx={{ ml: 2 }}>
            Purchases
          </Button>
          <Button variant={view === 'prizes' ? 'contained' : 'outlined'} onClick={() => setView('prizes')} sx={{ ml: 2 }}>
            Prizes
          </Button>
          <Button variant={view === 'top' ? 'contained' : 'outlined'} onClick={() => setView('top')} sx={{ ml: 2 }}>
            Daily Top
          </Button>
        </div>

        {renderTable()}
      </Paper>
    </Container>
  );
};

export default PigGame;
